@keyframes pulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.8;
  }
}

@keyframes pulseOpacity {
  0% {
    transform: translateX(-75%);
  }
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(175%);
  }
}

#smallbar {
  animation-name: pulseOpacity;
  animation-timing-function: linear;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

#pulseBar {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
  animation-duration: 1.25s;
  animation-iteration-count: infinite;
}
