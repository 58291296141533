@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700);
html,
body,
#root {
  -webkit-user-select: none;
          user-select: none;
  margin: 0;
  padding: 0;
  height: 100%;
  display: block;
  font-family: 'Montserrat', sans-serif;
}
.page {
  margin: auto;
  padding: 2rem;
  color: #4B4B4B;
  max-width: 960px;
  text-align: justify;
}
.page h1 {
  text-align: center;
  text-transform: uppercase;
  padding: 1.5rem;
  font-size: 26pt;
}
.page h2 {
  color: #1769AA;
}
/* Fix intojs over modal */
.introjs-fixParent {
  z-index: 999999 !important;
}
.introjs-helperLayer {
  opacity: 0.25;
}
.introjs-overlay {
  background-color: rgba(100, 100, 100, 0.75);
}
/* Fix PDFDownloadLink */
a {
  text-decoration: none;
}
.mr-10 {
  margin-right: 10px;
}

@keyframes pulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.8;
  }
}

@keyframes pulseOpacity {
  0% {
    transform: translateX(-75%);
  }
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(175%);
  }
}

#smallbar {
  animation-name: pulseOpacity;
  animation-timing-function: linear;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

#pulseBar {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
  animation-duration: 1.25s;
  animation-iteration-count: infinite;
}

